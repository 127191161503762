import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
  }
  _templateFunction(state) {
    if (!state.id) { return state.text; }
    return $(`<i class="${state.element.text.toLowerCase()}"></i>`)
  }
}
