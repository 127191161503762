import BluetoothController, { factoryResetCharacteristicUUID } from "../bluetooth_controller";
import showAlert from "../../../src/alerts";

import { notifyServerOnFactoryResetSuccess } from "../api";
export default class extends BluetoothController {
  static targets = ["actionIcon", "spinnerIcon", "resetButton"];

  static values = {
    successfulText: String,
    unsuccessfulText: String,
    requestData: String,
    confirmationText: String,
    deviceSecureCode: String,
    completedUrl: String,
  };

  connect() {
    super.connect();

    Android.handleBluetoothResponse = (passedDataValue) => {
      Android.stopSpinner();
      const requestData = document.querySelector(
        "[data-bluetooth--android--factory-reset-request-data-value]"
      ).getAttribute(
        "data-bluetooth--android--factory-reset-request-data-value"
      );
      const successfulText = document.querySelector(
        "[data-bluetooth--android--factory-reset-successful-text-value]"
      ).getAttribute(
        "data-bluetooth--android--factory-reset-successful-text-value"
      );
      const unsuccessfulText = document.querySelector(
        "[data-bluetooth--android--factory-reset-unsuccessful-text-value]"
      ).getAttribute(
        "data-bluetooth--android--factory-reset-unsuccessful-text-value"
      );

      if (passedDataValue === requestData) {
        document.querySelector("[data-bluetooth--android--factory-reset-target=\"resetButton\"]").classList.add("tw-hidden");

        showAlert(successfulText);
        const factoryResetSuccessUrl = document.querySelector(
          "[data-bluetooth--android--factory-reset-completed-url-value]"
        ).getAttribute(
          "data-bluetooth--android--factory-reset-completed-url-value"
        );

        notifyServerOnFactoryResetSuccess(factoryResetSuccessUrl);
      } else {
        showAlert(unsuccessfulText);
      }
    };

    Android.stopSpinner = () => {
      document.querySelector("[data-bluetooth--android--factory-reset-target=\"spinnerIcon\"]").classList.add("tw-hidden");
      document.querySelector("[data-bluetooth--android--factory-reset-target=\"actionIcon\"]").classList.remove("tw-hidden");
    };
  }

  disconnect() {
    super.disconnect();
    Android.disableBluetooth();
  }

  factoryReset() {
    this.startSpinner();
    const confirmed = confirm(this.confirmationTextValue);
    if (!confirmed) {
      this.stopSpinner();
      return;
    }

    try {
      const command = {
        description: "factoryReset",
        characteristicUUID: factoryResetCharacteristicUUID,
        value: this.requestDataValue,
        secureCode: this.deviceSecureCodeValue,
        disabledSecureCodes: [],
      };
      Android.promptBluetooth(JSON.stringify(command));
    } catch (error) {
      showAlert(this.unsuccessfulTextValue);
      this.stopSpinner();
    }
  }

  hideResetButton() {
    this.resetButtonTarget.classList.add("tw-hidden");
  }
}
