import BluetoothController, { onboardCharacteristicUUID } from "../bluetooth_controller"
import { decodeBluetoothResponse } from "../api"

export default class extends BluetoothController {
  static targets = ["actionIcon", "linkBlock", "spinnerIcon"]

  static values = {
    requestData: String,
    responseUrl: String,
    deviceSecureCode: String,
  }

  connect() {
    super.connect()
    document.addEventListener("onboard_response", this.handleBluetoothResponseFromIosApp.bind(this))
  }

  disconnect() {
    super.disconnect()
    document.removeEventListener("onboard_response", this.handleBluetoothResponseFromIosApp.bind(this))
    window.webkit.messageHandlers.disableBluetooth.postMessage("")
  }

  bluetoothConnect() {
    this.startSpinner()

    const command = {
      description: "pair with lamp",
      "characteristicUUID": onboardCharacteristicUUID,
      "value": this.requestDataValue,
      "secureCode": this.deviceSecureCodeValue,
    }

    window.webkit.messageHandlers.enableBluetooth.postMessage(command)
    this.disabledValue = true
  }

  handleBluetoothResponseFromIosApp(event) {
    this.lampConfigurationStarted()
    this.linkBlockTarget.classList.add("tw-hidden")
    decodeBluetoothResponse(event.detail, this.responseUrlValue)
  }
}
