import BluetoothController,  { onboardServiceUUID, factoryResetCharacteristicUUID }  from "../bluetooth_controller";
import showAlert from "../../../src/alerts";
import base64ToArrayBuffer from "../../../src/base64";
import { notifyServerOnFactoryResetSuccess } from "../api";
import * as Sentry from "@sentry/browser";

export default class extends BluetoothController {
  static targets = ["actionIcon", "spinnerIcon", "resetButton"];

  static values = {
    successfulText: String,
    unsuccessfulText: String,
    requestData: String,
    confirmationText: String,
    enableBluetoothText: String,
    deviceSecureCode: String,
    completedUrl: String,
  };

  hideResetButton() {
    this.resetButtonTarget.classList.add("tw-hidden");
  }

  async factoryReset() {
    this.startSpinner();
    const confirmed = confirm(this.confirmationTextValue);
    if (!confirmed) {
      this.stopSpinner();
      return;
    }

    if (!navigator.bluetooth) {
      showAlert(this.enableBluetoothTextValue);
      return;
    }

    try {
      let device =  await navigator.bluetooth.requestDevice(this.deviceFilters());

      window.bluetooth_device_name = device.name;
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(onboardServiceUUID);
      const characteristic = await service.getCharacteristic(factoryResetCharacteristicUUID);
      await characteristic.writeValue(base64ToArrayBuffer(this.requestDataValue));

      this.hideResetButton();
      showAlert(this.successfulTextValue);

      notifyServerOnFactoryResetSuccess(this.completedUrlValue);
      this.stopSpinner();
    } catch(error) {
      Sentry.captureException(error);
      console.log(error);
      this.stopSpinner();
      showAlert(this.unsuccessfulTextValue);
    }
  }
}
