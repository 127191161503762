import 'bootstrap-notify';

$.notifyDefaults({
  placement: {
    from: 'top',
    align: 'center'
  }
});
document.addEventListener('turbo:load', function () {
  if (typeof Dashmix !== "undefined" && Dashmix !== null) {
    Dashmix._uiInit();
  }
  if (Cookies.get('notification') != null && Cookies.get('forcerefresh') == null) {
    $.notify(Cookies.get('notification'), {
      type: 'success'
    });
    Cookies.remove('notification');
  }
  if (Cookies.get('forcerefresh') != null) {
    setTimeout(function () {
      Cookies.remove('forcerefresh');
      Turbo.visit(window.location.href);
    }, 300);
  }
  if (typeof stagingDeviceLoopId !== "undefined" && stagingDeviceLoopId !== null && window.location.pathname.search(/staging_devices\/\d/) === -1) {
    clearInterval(stagingDeviceLoopId);
  }
});
window.popup = function (message, options) {
  if (navigator.userAgent.includes('nobi-ios')) {
    if (options != null && options['type'] === 'success') {
      // in case of success message we just display it, not as a popup
      return $.notify(message, options);
    } else {
      return window.webkit.messageHandlers.alert.postMessage(message);
    }
  } else {
    return $.notify(message, options);
  }
};
