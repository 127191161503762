import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (typeof Android === 'undefined') {
      return
    }

    Android.androidSubscriptionStatusChange = (event) => {
      console.log("androidSubscriptionStatusChange no op, to avoid undefined method inside the android application when user is not logged in.")
    }
  }
}
