import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["title", "message", "confirm", "cancel"]

  connect() {
    this.originalTitle = this.titleTarget.textContent
    this.originalMessage = this.messageTarget.textContent
    this.originalConfirm = this.confirmTarget.textContent
    this.originalCancel = this.cancelTarget.textContent
  }

  showModal({ detail: { title, message, confirm, cancel } }) {
    if (title) this.titleTarget.textContent = title
    this.messageTarget.textContent = message
    if (confirm) this.confirmTarget.textContent = confirm
    if (cancel) this.cancelTarget.textContent = cancel
    this.element.showModal()
  }

  confirm() {
    this._closeModal(true)
  }

  cancel() {
    this._closeModal(false)
  }

  _closeModal(value) {
    this._reset()
    window.dispatchEvent(new CustomEvent("confirm-dialog-close", { detail: { value } }))
  }

  _reset() {
    this.titleTarget.textContent = this.originalTitle
    this.messageTarget.textContent = this.originalMessage
    this.confirmTarget.textContent = this.originalConfirm
    this.cancelTarget.textContent = this.originalCancel
  }
}
