import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "processIcon", "imageList" ]
  static classes = [ "hidden" ]

  initialize() {
    this.escalationEventId = parseInt(this.data.get('id'));
  }

  connect() {
    this.requestingImage = false;
  }

  showProcessing() {
    this.requestingImage = true;
    this.processIconTarget.classList.remove(this.hiddenClass);
  }

  hideProcessing() {
    this.processIconTarget.classList.add(this.hiddenClass);
    this.requestingImage = false;
  }

  retrieveImage(image, tryCount) {
    let escalationEventController = this;
    if (tryCount == 10) {
      $.notify(window.I18n.error_occurred_requesting_image);
      escalationEventController.hideProcessing();
    } else
    {
      $.ajax({
        type: 'GET',
        url: "/escalation_events/" + this.escalationEventId + "/images/get_image",
        dataType: 'json',
        data: {
          authenticity_token: settings.token,
          taken_on: image
        },
        success: function (data) {
          if (data.responseText != '') {
            escalationEventController.imageListTarget.insertAdjacentHTML('afterbegin', data['html']);
            escalationEventController.hideProcessing();
          }
        },
        complete: function (data) {
          // Schedule the next
          if (data.responseText == '')
          {
            escalationEventController.retrieveImage(image, tryCount + 1);
          }
        }
      });
    }
  }

  requestImage() {
    if (this.requestingImage == false) {
      this.showProcessing();
      let escalationEventController = this;
      Promise.resolve(window.currentImageRequest = $.ajax({
        url: "/escalation_events/" + this.escalationEventId + "/images/take",
        type: 'patch',
        data: {
          authenticity_token: settings.token
        },
        success: function(response) {
          setTimeout(escalationEventController.retrieveImage(response, 1), 2000);
        },
        timeout: 15000
      }))["catch"](function(e) {
        $.notify(window.I18n.error_occurred_requesting_image);
        escalationEventController.hideProcessing();
      });
    }
  }
}
