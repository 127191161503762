import BluetoothController, { onboardCharacteristicUUID } from "../bluetooth_controller"
import { decodeBluetoothResponse } from "../api"

export default class extends BluetoothController {
  static targets = ["actionIcon", "linkBlock", "spinnerIcon"]

  static values = {
    requestData: String,
    responseUrl: String,
    deviceSecureCode: String,
    disabledSecureCodes: Array,
  }

  connect() {
    super.connect()

    Android.handleBluetoothResponse = (bluetoothResponse) => {
      this.lampConfigurationStarted()

      document.querySelector("[data-bluetooth--android--onboard-target=\"linkBlock\"]").classList.add("tw-hidden")
      const responseUrl = document.querySelector(
        "[data-bluetooth--android--onboard-response-url-value]"
      ).getAttribute(
        "data-bluetooth--android--onboard-response-url-value"
      )

      decodeBluetoothResponse(bluetoothResponse, responseUrl)
    }

    Android.stopSpinner = () => {
      document.querySelector("[data-bluetooth--android--onboard-target=\"spinnerIcon\"]").classList.add("tw-hidden")
      document.querySelector("[data-bluetooth--android--onboard-target=\"actionIcon\"]").classList.remove("tw-hidden")
    }
  }

  disconnect() {
    super.disconnect()
    Android.disableBluetooth()
  }

  bluetoothConnect() {
    this.startSpinner()

    const command = {
      description: "pair with lamp",
      characteristicUUID: onboardCharacteristicUUID,
      value: this.requestDataValue,
      disabledSecureCodes: [],
    }
    if (this.deviceSecureCodeValue) {
      command.secureCode = this.deviceSecureCodeValue
    }
    if (this.disabledSecureCodesValue) {
      command.disabledSecureCodes = this.disabledSecureCodesValue
    }

    Android.promptBluetooth(JSON.stringify(command))
    this.disabledValue = true
  }
}
