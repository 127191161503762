import { Controller } from '@hotwired/stimulus'

let currentDiscoverRequest = null
let currentPairRequest = null

export default class extends Controller {
  initialize() {
  }

  connect() {
    const type = this.data.get('type')
    const message = this.data.get('message')
    if ((navigator.userAgent.includes('nobi-ios')) && (type != 'success')) {
      window.webkit.messageHandlers.alert.postMessage(message);
    } else {
      const notify = $.notify(
        { message: message },
        {
          type: type,
          allow_dismiss: true,
          placement: {
            align:'center'
          },
          animate: {
            enter: 'tw-animate-[bounce_1.2s_ease-out_forwards]',
            exit: 'tw-animate-[bounce_1.2s_ease-out_forwards]'
          }
        }
      )
      // Close notifications before we're going to next page
      $(document).one('turbo:before-visit', () => {
        notify.close()
      })

      // Destroy notifications at all before turbo cache page content
      // This is hiding some messages when a get action redirects to another page with a notice
      // the message is only blinking.
      $(document).one('turbo:before-cache', () => {
        notify.$ele.remove()
      })
    }
    $(this.element).remove()
  }
}
