import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['refreshButton']

  refresh() {
    this.refreshButtonTarget.click()
  }
}

