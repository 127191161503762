import {config} from "@hotwired/turbo"

// Hook Turbo confirm with custom Stimulus controller.
config.forms.confirm = (message, _element, submitter) => {
  const title = submitter?.dataset["confirmTitle"]
  const confirm = submitter?.dataset["confirmConfirm"]
  const cancel = submitter?.dataset["confirmCancel"]
  window.dispatchEvent(new CustomEvent("confirm-dialog-open", {
    detail: { title, message, confirm, cancel }
  }))
  return new Promise(resolve => {
    window.addEventListener("confirm-dialog-close", ({ detail: { value } }) => resolve(value))
  }, { once: true })
}
