import { Controller } from "@hotwired/stimulus";

export const onboardServiceUUID = "c8606c5b-ef1a-4461-b618-a3c71c4a6405";
export const onboardDoneServiceUUID = "00006e6f-0000-1000-8000-00805f9b34fb";
export const factoryResetCharacteristicUUID = "d50d4865-64de-497d-92ba-0551d952bad6";
export const onboardCharacteristicUUID = "693b608c-210f-47a8-a7f5-b97488d9b535";

export default class extends Controller {
  static targets = ["actionIcon", "spinnerIcon"];

  static values = {
    deviceSecureCode: String,
  };

  connect() {
    document.addEventListener("bluetooth_status", this.handleBluetoothStatusChange.bind(this));
  }

  disconnect() {
    document.removeEventListener("bluetooth_status", this.handleBluetoothStatusChange.bind(this));
  }

  startSpinner() {
    this.actionIconTarget.classList.add("tw-hidden");
    this.spinnerIconTarget.classList.remove("tw-hidden");
  }

  stopSpinner() {
    this.actionIconTarget.classList.remove("tw-hidden");
    this.spinnerIconTarget.classList.add("tw-hidden");
  }

  handleBluetoothStatusChange(event) {
    if (event.detail === "stopped") {
      this.stopSpinner();
    }
  }

  deviceFilters() {
    if (this.deviceSecureCodeValue) {
      return {
        filters: [{ services: [onboardServiceUUID], name: ["Nobi", this.deviceSecureCodeValue].join(" ") }]
      }
    } else {
      return {
        filters: [{ services: [onboardServiceUUID] }],
        exclusionFilters: [{ services: [onboardDoneServiceUUID] }]
      }
    }
  }

  lampConfigurationStarted() {
    var completed_classes = document.getElementById("wizard_step_2").getAttribute("data-completed-classes").split(" ")
    var active_classes = document.getElementById("wizard_step_2").getAttribute("data-active-classes").split(" ")
    document.getElementById("wizard_step_2").classList.remove(...active_classes)
    document.getElementById("wizard_step_2").classList.add(...completed_classes)
    document.getElementById("wizard_step_3").classList.add(...active_classes)
  }
}
