import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['hiddenContainer']
    static values = { length: Number }

    change(event) {
      if (event.target.value.length > this.lengthValue) {
        this.hiddenContainerTarget.classList.remove('tw-hidden')
      } else {
        this.hiddenContainerTarget.classList.add('tw-hidden')
      }
    }
}
