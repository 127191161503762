import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static outlets = [ "live-view" ]
  static values = {
    wsConnected: Boolean,
    wsFirstConnectionPerformed: Boolean
  }

  connect() {
    this._actionCableConnectionMonitoring()
  }

  disconnect() {
    this._stopActionCableConnectionMonitoring()
  }

  _actionCableConnectionMonitoring() {
    this.connectionObserver = new MutationObserver((mutationList)=>{
      this._handleWsConnectionChange(mutationList)
    })

    // https://github.com/hotwired/turbo-rails/issues/434#issuecomment-1428713863
    const streamTag = document.querySelector("turbo-cable-stream-source")
    this.connectionObserver.observe(streamTag, { attributeFilter: ["connected"] })
    this._refreshWsConnectionIndicator(streamTag)
  }

  _stopActionCableConnectionMonitoring() {
    this.connectionObserver.disconnect()
  }

  _handleWsConnectionChange(mutationList){
    mutationList.forEach((mutation) => {
      switch (mutation.type) {
        case "attributes":
          switch (mutation.attributeName) {
            case "connected":
              this._refreshWsConnectionIndicator(mutation.target)
              break;
          }
          break;
      }
    });
  }

  _refreshWsConnectionIndicator(streamTag){
    if(this._isWsConnected(streamTag)){
      this._wsConnected()
    } else {
      this._wsDisconnected()
    }
  }

  _isWsConnected(streamTag){
    return streamTag.hasAttribute("connected")
  }

  _wsConnected() {
    this._setIndicatorAsConnected()

    if (!this.wsConnectedValue && this.wsFirstConnectionPerformedValue) {
      console.debug("websocket connection restored, reloading page")
      Turbo.cache.clear()
      Turbo.visit(window.location)
    }
    this._markAsConnected()
  }

  _wsDisconnected() {
    this._setIndicatorAsDisconnected()
    this._markAsDisconnected()
  }

  _markAsDisconnected(){
    this.wsConnectedValue = false
  }

  _markAsConnected(){
    this.wsFirstConnectionPerformedValue = true
    this.wsConnectedValue = true
  }

  _setIndicatorAsConnected(){
    this.element.classList.add("tw-text-legacy-success")
    this.element.classList.remove("tw-text-legacy-danger")
  }

  _setIndicatorAsDisconnected(){
    this.element.classList.remove("tw-text-legacy-success")
    this.element.classList.add("tw-text-legacy-danger")
  }
}
